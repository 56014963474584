.react-flow__node {
  width: fit-content;
  font-size: 12px;
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #1a192b;
  background-color: white;
  padding: 10px;
  border-radius: 7px;
  min-height: fit-content;
}

.node-exterior {
  width: 100px; 
  height: 100px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -50%;
  top: 0%;
  font-size: 14px;
  font-weight: bold;
}

.node-exterior * {
  margin: 0;
}


.node-exterior .node-variables {
  display: flex;
  justify-content: center;
  flex-direction: column ;

  gap: 5px;
}

.react-flow__node.selected {
  border-color: rgba(36, 123, 199, 0.629);
  border-width: 3.5px;
  box-shadow: 0 0 10px 2px rgba(36, 123, 199, 0.3);
}


.react-flow__node select,
.react-flow__node input[type="text"],
.react-flow__node input[type="number"],
.react-flow__node input[type="email"],
.react-flow__node input[type="password"] {
  margin: 10px 0;
  appearance: none;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 5px 7px;
  font-size: 16px;
  color: #374151;
  width: 100%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  min-width: 100px;
}

.react-flow__node select {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 14px center;
}

.react-flow__node select:hover,
.react-flow__node input[type="text"]:hover,
.react-flow__node input[type="number"]:hover,
.react-flow__node input[type="email"]:hover,
.react-flow__node input[type="password"]:hover {
  border-color: #d1d5db;
  background-color: #ffffff;
}

.react-flow__node select:focus,
.react-flow__node input[type="text"]:focus,
.react-flow__node input[type="number"]:focus,
.react-flow__node input[type="email"]:focus,
.react-flow__node input[type="password"]:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.react-flow__node input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #e5e7eb;
  border-radius: 4px;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.react-flow__node input[type="checkbox"]:checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.react-flow__node input[type="checkbox"]:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.react-flow__node input[type="checkbox"]:hover {
  border-color: #d1d5db;
}

.react-flow__node input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}


.react-flow__node h3 {
  margin: 0;
}

.node-interior {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.config-fields {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
}

.config-fields > label {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.config-fields-label {
  display: inline-block;
  min-width: 70px;
  font-size: 16px;
  box-sizing: border-box;
}
