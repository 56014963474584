.add-new-node-btn, .create-close-btn button {
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #4b229d, #247bc7);
}

.add-new-node-btn:hover, .create-close-btn button:hover {
  background: linear-gradient(to right, #321769, #206aac);
}

.create-node-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
  border-radius: 10px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-node-popup h2 {
  margin: 0 0 20px 0;
}

.create-node-popup select,
.create-node-popup input[type="text"],
.create-node-popup input[type="number"],
.create-node-popup input[type="email"],
.create-node-popup input[type="password"] {
  margin: 10px 0;
  appearance: none;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px 14px;
  font-size: 16px;
  color: #374151;
  width: 100%;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
}

.create-node-popup select {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 14px center;
}

.create-node-popup select:hover,
.create-node-popup input[type="text"]:hover,
.create-node-popup input[type="number"]:hover,
.create-node-popup input[type="email"]:hover,
.create-node-popup input[type="password"]:hover {
  border-color: #d1d5db;
  background-color: #ffffff;
}

.create-node-popup select:focus,
.create-node-popup input[type="text"]:focus,
.create-node-popup input[type="number"]:focus,
.create-node-popup input[type="email"]:focus,
.create-node-popup input[type="password"]:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.create-node-popup input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #e5e7eb;
  border-radius: 4px;
  background-color: #f3f4f6;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.create-node-popup input[type="checkbox"]:checked {
  background-color: #3b82f6;
  border-color: #3b82f6;
}

.create-node-popup input[type="checkbox"]:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.create-node-popup input[type="checkbox"]:hover {
  border-color: #d1d5db;
}

.create-node-popup input[type="checkbox"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.create-close-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px 0;
}

.create-close-btn > button:nth-of-type(2) {
  background: linear-gradient(to right, red, rgb(183, 3, 3));
}

.create-node-configs {
  width: 100%;
}

.create-node-checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create-node-checkboxes label {
  display: flex;
  justify-content: center;
  align-items: center;
}